import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { useEffect, useLayoutEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Header from './header';

import { isLoggedIn } from 'src/utils/isLoggedIn';
import { IRootState } from 'src/store';

import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import {
  ConnectionStatus,
  getClosetListRequest,
  setActiveCloset,
} from 'src/store/automations/slices/myClosetSlice';

import NavHorizontal from '../LayoutComponents/NavHorizontal';
import createNavConfig from './nav/config';
import { Box, useMediaQuery } from '@mui/material';
import Main from '../LayoutComponents/Main';
import 'src/utils/onBoardingSteps/onBoardingCustomStyle.css';
import useStripeRedirectedUser from 'src/hooks/useStripeRedirectedUser';
import LayoutAlertContainer from '../LayoutComponents/LayoutAlertContainer';
import useInitializeExtensionAndThirdParties from 'src/hooks/useInitializeExtensionAndThirdParties';
import LoadingScreen from 'src/components/loading-screen';
import { setHasUserChangedTheSliderValue } from 'src/store/dashboard/slices/pricingSlice';
import {
  resetAutomationsState,
  setActiveClosetAutomationPlatform,
  setShowMobileAppReviewDialog,
} from 'src/store/automations/slices/automationsSlice';
import useResponsive from 'src/hooks/useResponsive';
import { ITaskCountsObject } from 'src/store/automations/slices/statisticsSlice';
import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';
import { setHasAnyAlerts } from 'src/store/dashboard/slices/homeSlice';
import {
  setHasActiveSearch,
  setIsPortalOpen,
  setMyListings,
  setSelectedFilterOption,
  setSelectedListings,
  setSelectedSortOption,
} from 'src/store/automations/slices/myListingsSlice';
import {
  filterOptionsList,
  sortOptionsList,
} from 'src/pages/automations/MyListingsPage/utils/constants';
import useAutomationRedirect from 'src/hooks/useAutomationRedirect';
// ----------------------------------------------------------------------

export default function AutomationsLayout() {
  const {
    hasCloset,
    hasPoshmarkSubscription,
    isGetClosetRequestCompleted,
    activeCloset,
    closetList,
    activeClosetCredentialsId,
    hasActiveClosetConnection,
  } = useSelector((state: IRootState) => state.myCloset);
  const { isMobileAppRateSubmitted, isRedirectingToRelevantPage, loading } = useSelector(
    (state: IRootState) => state.automations
  );
  const { taskCountsObject } = useSelector((state: IRootState) => state.statistics);
  const { isPrimeListerMobileApp, primeListerMobileAppData } = useSelector(
    (state: IRootState) => state.home
  );
  const { isPortalOpen } = useSelector((state: IRootState) => state.myListings);
  const { featureFlags } = useSelector((state: IRootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const TOTAL_TASK_COUNT_TO_SHOW_RATING_POPUP = 1000;
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const { userId } = isLoggedIn();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobileWeb = useResponsive('down', 'sm');
  const isDesktop = useResponsive('up', 'sm');

  const isPricingPage = location.pathname.includes(PATH_DASHBOARD.subscription.root);
  const isMyClosetPage = location.pathname.includes(PATH_AUTOMATIONS.root);
  // We may extend this with other paths in the future
  const ratingPopupPathConditions = [PATH_AUTOMATIONS.activity];
  //--Handles the flow for redirectedusers
  useStripeRedirectedUser();
  //--extension and third party apps
  useInitializeExtensionAndThirdParties(location, userId);
  //--Automation redirection
  useAutomationRedirect();

  const getTotalTaskCounts = (taskCountsObject: ITaskCountsObject): number =>
    Object.values(taskCountsObject).reduce((total: number, count: number | null) => {
      // Check if count is not null, then add it to total, otherwise just return total
      if (count !== null) {
        return total + count;
      } else {
        return total;
      }
    }, 0); // Initial value of 0 ensures total starts as a number

  useEffect(() => {
    dispatch(getClosetListRequest());
  }, []);

  const shouldShowRatingPopup = () =>
    !isMobileAppRateSubmitted &&
    activeClosetCredentialsId &&
    hasActiveClosetConnection &&
    ratingPopupPathConditions.some((path) => location.pathname.includes(path)) &&
    Object.keys(taskCountsObject).length > 0 && // Explicitly check for non-empty object
    getTotalTaskCounts(taskCountsObject) >= TOTAL_TASK_COUNT_TO_SHOW_RATING_POPUP &&
    primeListerMobileAppData?.version;

  useEffect(() => {
    if (shouldShowRatingPopup()) {
      dispatch(setShowMobileAppReviewDialog(true));
    }
  }, [activeClosetCredentialsId, isMobileAppRateSubmitted, taskCountsObject, location.pathname]);

  useEffect(() => {
    if (!isPricingPage) {
      dispatch(setHasUserChangedTheSliderValue(false));
    }
  }, [isPricingPage]);

  useEffect(() => {
    const shopQueryParam = searchParams.get('shop');
    const referrerQueryParam = searchParams.get('referrer');

    if (!referrerQueryParam && shopQueryParam) {
      const initialCloset = closetList.find(
        (closet) => shopQueryParam === closet?.poshmarkUserName
      );
      if (initialCloset) {
        dispatch(resetAutomationsState())
        dispatch(setActiveCloset(initialCloset));
        dispatch(setActiveClosetAutomationPlatform(initialCloset?.country));
      }
    } else {
      if (referrerQueryParam || (!shopQueryParam && activeCloset?.poshmarkUserName)) {
        if (activeCloset?.poshmarkUserName && !pathname.includes(PATH_AUTOMATIONS.account) && !pathname.includes('subscription')) {
          setTimeout(() => {
            // This is for when shop param is set we must preserve other params too
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.set('shop', activeCloset.poshmarkUserName); // Set or update the shop param
            window.history.replaceState(
              null,
              '',
              `${window.location.pathname}?${currentParams.toString()}${window.location.hash}`
            );
          }, 500);
        }
      }
    }
  }, [window.location.search, closetList, window.location.href]);

  const shouldHideNavHorizontal = location.pathname.includes(PATH_DASHBOARD.subscription.root);

  const shouldDisplayConnectionAlert = !hasCloset && !isMyClosetPage;
  const shouldDisplayReconnectionAlert =
    hasCloset &&
    closetList.filter((closet) => closet?.status === ConnectionStatus.Disconnected).length > 0;
  const shouldDisplayPoshmarkSubscriptionAlert = !hasPoshmarkSubscription;

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  const hasAnyAlerts =
    shouldDisplayConnectionAlert ||
    shouldDisplayReconnectionAlert ||
    shouldDisplayPoshmarkSubscriptionAlert;

  useEffect(() => {
    dispatch(setHasAnyAlerts(hasAnyAlerts));
  }, [hasAnyAlerts]);

  const alerts = {
    shouldDisplayConnectionAlert,
    shouldDisplayReconnectionAlert,
    shouldDisplayPoshmarkSubscriptionAlert,
  };

  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const shouldShowHeader =
    isDesktop || (location.pathname.includes(PATH_AUTOMATIONS.account) && isMobile);

  const navConfig = createNavConfig(
    isMobileWeb,
    isPrimeListerMobileApp,
    (featureFlags as IFeatureFlag[]) ?? []
  );
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes('my-listings')) {
      handleClearMyListingsFilters();
      dispatch(setIsPortalOpen(false));
    }
  }, [pathname]);

  const handleClearMyListingsFilters = () => {
    dispatch(setSelectedFilterOption(filterOptionsList[1]));
    dispatch(setSelectedSortOption(sortOptionsList[0]));
    dispatch(setSelectedListings(new Set()));
    dispatch(setMyListings([]));
    dispatch(setHasActiveSearch(false));
  };

  const showWithPortal = isPortalOpen && pathname.includes(PATH_AUTOMATIONS.listings);

  return (
    <>
      {isGetClosetRequestCompleted && !isRedirectingToRelevantPage ? (
        <>
          {shouldShowHeader && <Header />}
          {!isPortalOpen && !shouldHideNavHorizontal && !navbarMatches && (
            <NavHorizontal navConfig={navConfig} />
          )}
          <Box
            id="portal-div"
            sx={{
              visibility: isPortalOpen ? 'visible' : 'hidden',
              backgroundColor: isPortalOpen ? '#F4F6F8' : 'transparent',
              height: isPortalOpen ? '56px' : '0',
              position: 'fixed',
              top: !isMobile ? '60px' : '0',
              width: '100%',
              zIndex: 20,
              right: 0,
              left: 0,
            }}
          />

          <Main hasAlert={hasAnyAlerts}>
            {hasAnyAlerts && <LayoutAlertContainer alerts={alerts} isInAutomations={true} />}
            <Outlet />
          </Main>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
