import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAutomationOption,
  IDataForUpdateAutomationSettings,
  IAutomationWorkerData,
} from 'src/pages/automations/AutomationsPage/types/types';
import { getClosetCountry } from 'src/pages/automations/MyClosetPage/helpers/helpers';
import { Marketplace, PoshmarkSettings, TaskHourConfig } from '@beta.limited/primelister';
import { RenderTree } from 'src/pages/automations/MyListingsPage/components/shared/types/CategoryTreeView';

export enum UserTagTypesEnum {
  ONBOARDING_POSHMARK_AUTOMATION = 'onboarding_poshmark_automation',
  ONBOARDING_POSHMARK_AUTOMATION_FEATURES = 'onboarding_poshmark_automation_features',
  ONBOARDING_MULTIPLE_CLOSETS = 'onboarding_multiple_closets',
  ONBOARDING_INVENTORY_FULLSCREEN = 'onboarding_inventory_fullscreen',
  MOBILE_APP_RATE_SUBMITTED = 'mobile_app_rate_submitted',
  MY_SHOPS_API_ONBOARDING = 'my_shops_api_onboarding',
}

export interface IAutomationsState {
  loading: boolean;
  initialLoading: boolean;
  error: string;
  automationsSettings: PoshmarkSettings;
  isDisableConfirmationDialogOpen: boolean;
  dataForUpdating: IDataForUpdateAutomationSettings;
  selectedAutomationOption: IAutomationOption;
  isAutomationOptionDrawerOpen: boolean;
  activeClosetAutomationPlatform: Marketplace;
  activeClosetPlatformShippingOffers: {
    label: string;
    option: string;
    customValue?: string;
  }[];
  isConnectionErrorDialogOpen: boolean;
  isOnBoardingActive: boolean;
  isUserBoardedPoshmarkAutomation: boolean;
  isUserBoardedPoshmarkAutomationFeatures: boolean;
  isUserBoardedMultipleClosets: boolean;
  isShareHourDialogOpen: boolean;
  selectedShareHourData: TaskHourConfig;
  isRelistHourDialogOpen: boolean;
  selectedRelistHourData: TaskHourConfig;
  activeOnboardingType: UserTagTypesEnum | '';
  hourlyRelistLimit: number;
  relistHourDialogError: string;
  relistLimitError: string;
  isUserBoardedInventoryFullscreen: boolean;
  showMobileAppReviewDialog: boolean;
  isMobileAppRateSubmitted: boolean;
  isRedirectingToRelevantPage: boolean;
  filterCategories: RenderTree;
  rawFilterCategories: RenderTree[];
  isRuleDeleteDialogOpen: boolean;
}

const initialState = {
  initialLoading: false,
  loading: false,
  error: '',
  automationsSettings: {},
  isDisableConfirmationDialogOpen: false,
  dataForUpdating: {},
  selectedAutomationOption: {} as IAutomationOption,
  isAutomationOptionDrawerOpen: false,
  activeClosetAutomationPlatform: Marketplace.POSHMARK_US,
  activeClosetPlatformShippingOffers: [],
  isConnectionErrorDialogOpen: false,
  isOnBoardingActive: false,
  isUserBoardedPoshmarkAutomation: true,
  isUserBoardedPoshmarkAutomationFeatures: true,
  isUserBoardedMultipleClosets: true,
  isShareHourDialogOpen: false,
  selectedShareHourData: {} as TaskHourConfig,
  isRelistHourDialogOpen: false,
  selectedRelistHourData: {} as TaskHourConfig,
  activeOnboardingType: '',
  hourlyRelistLimit: 0,
  relistHourDialogError: '',
  relistLimitError: '',
  isUserBoardedInventoryFullscreen: true,
  showMobileAppReviewDialog: false,
  isMobileAppRateSubmitted: false,
  isRedirectingToRelevantPage: true,
  filterCategories: {},
  rawFilterCategories: [] as RenderTree[],
  isRuleDeleteDialogOpen: false,
};

const automationsSlice = createSlice({
  name: 'automations',
  initialState,
  reducers: {
    setIsRuleDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isRuleDeleteDialogOpen = action.payload;
    },
    setActiveClosetAutomationPlatform: (state, action: PayloadAction<string>) => {
      state.activeClosetAutomationPlatform = getClosetCountry(action.payload);
    },
    setIsConnectionErrorDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isConnectionErrorDialogOpen = action.payload;
    },
    setAutomationError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setRelistHourDialogError: (state, action: PayloadAction<string>) => {
      state.relistHourDialogError = action.payload;
    },
    setRelistLimitError: (state, action: PayloadAction<string>) => {
      state.relistLimitError = action.payload;
    },
    //--
    setAutomationsSettings: (state, action: PayloadAction<PoshmarkSettings>) => {
      state.automationsSettings = action.payload;
    },
    setHourlyRelistLimit: (state, action: PayloadAction<number>) => {
      state.hourlyRelistLimit = action.payload;
    },
    setIsDisableConfirmationDialogOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean; dataForUpdating: IDataForUpdateAutomationSettings }>
    ) => {
      state.isDisableConfirmationDialogOpen = action.payload.isOpen;
      state.dataForUpdating = action.payload.dataForUpdating;
    },
    setIsAutomationOptionDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isAutomationOptionDrawerOpen = action.payload;
    },
    setSelectedAutomationOption: (state, action: PayloadAction<IAutomationOption>) => {
      state.selectedAutomationOption = action.payload;
    },
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },
    setIsRedirectingToRelevantPage: (state, action: PayloadAction<boolean>) => {
      state.isRedirectingToRelevantPage = action.payload;
    },
    setIsOnBoardingActive: (state, action: PayloadAction<boolean>) => {
      state.isOnBoardingActive = action.payload;
    },
    setIsShareHourDialogOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean; sharingHourDialogData: TaskHourConfig }>
    ) => {
      const { isOpen, sharingHourDialogData } = action.payload;
      state.isShareHourDialogOpen = isOpen;
      state.selectedShareHourData = sharingHourDialogData;
    },
    setSelectedShareHourData: (state, action: PayloadAction<TaskHourConfig>) => {
      state.selectedShareHourData = action.payload;
    },
    setIsRelistHourDialogOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean; relistingHourDialogData?: TaskHourConfig }>
    ) => {
      const { isOpen, relistingHourDialogData } = action.payload;
      state.isRelistHourDialogOpen = isOpen;
      if (relistingHourDialogData) {
        state.selectedRelistHourData = relistingHourDialogData;
      }
    },
    setSelectedRelistHourData: (state, action: PayloadAction<TaskHourConfig>) => {
      state.selectedRelistHourData = action.payload;
    },
    setActiveOnboardingType: (state, action: PayloadAction<UserTagTypesEnum>) => {
      state.activeOnboardingType = action.payload;
    },
    resetAutomationsSettings: (state) => {
      state.automationsSettings = {};
    },
    //--
    getAutomationListRequest: (
      state,
      action: PayloadAction<{ marketplace: string; activeClosetCredentialsId: string }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    getAutomationListSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    getAutomationListFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    updateAutomationSettingsRequest: (
      state,
      action: PayloadAction<IDataForUpdateAutomationSettings>
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateAutomationSettingsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    updateAutomationSettingsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //--
    getActiveClosetPlatformShippingOffersRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = '';
    },
    getActiveClosetPlatformShippingOffersSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.activeClosetPlatformShippingOffers = action.payload;
    },
    getActiveClosetPlatformShippingOffersFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.activeClosetPlatformShippingOffers = [];
    },
    //--
    createAutomationWorkerRequest: (state, action: PayloadAction<IAutomationWorkerData>) => {
      state.loading = true;
      state.error = '';
    },
    createAutomationWorkerSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    createAutomationWorkerFail: (state) => {
      state.loading = false;
      state.error = '';
    },
    //--GET_USER_TAGS
    getUserTagsRequest: (state, action: PayloadAction<{ userId: string }>) => {
      state.error = '';
    },
    getUserTagsSuccess: (
      state,
      action: PayloadAction<{
        poshmarkAutomation: boolean;
        poshmarkAutomationFeatures: boolean;
        multipleCloset: boolean;
        inventoryFullscreen: boolean;
        isMobileAppRateSubmitted: boolean;
      }>
    ) => {
      const {
        poshmarkAutomation,
        poshmarkAutomationFeatures,
        multipleCloset,
        inventoryFullscreen,
      } = action.payload;

      state.isUserBoardedPoshmarkAutomation = poshmarkAutomation;
      state.isUserBoardedPoshmarkAutomationFeatures = poshmarkAutomationFeatures;
      state.isUserBoardedMultipleClosets = multipleCloset;
      state.isUserBoardedInventoryFullscreen = inventoryFullscreen;
      state.isMobileAppRateSubmitted = action.payload.isMobileAppRateSubmitted;
      state.error = '';
    },
    getUserTagsFail: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    //--SET_USER_TAGS
    setUserTagsRequest: (
      state,
      action: PayloadAction<{ userId: string; userTagType: UserTagTypesEnum }>
    ) => {
      state.error = '';
    },
    setUserTagsSuccess: (
      state,
      action: PayloadAction<{
        poshmarkAutomation: boolean;
        poshmarkAutomationFeatures: boolean;
        multipleCloset: boolean;
        inventoryFullscreen: boolean;
        isMobileAppRateSubmitted: boolean;
      }>
    ) => {
      state.isUserBoardedPoshmarkAutomation = action.payload.poshmarkAutomation;
      state.isUserBoardedPoshmarkAutomationFeatures = action.payload.poshmarkAutomationFeatures;
      state.isUserBoardedMultipleClosets = action.payload.multipleCloset;
      state.isMobileAppRateSubmitted = action.payload.isMobileAppRateSubmitted;
      state.isUserBoardedInventoryFullscreen = action.payload.inventoryFullscreen;
      state.error = '';
    },
    setUserTagsFail: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    getFilterCategoriesRequest: (
      state,
      action: PayloadAction<{
        credentialsId: string;
        department?: string;
        category?: string;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },

    getFilterCategoriesSuccess: (state, action: PayloadAction<RenderTree>) => {
      state.error = '';
      state.loading = false;
      state.filterCategories = action.payload;
      state.rawFilterCategories = action.payload.children as RenderTree[];
    },

    getFilterCategoriesFail: (state, action) => {
      state.error = action.payload;
    },
    setShowMobileAppReviewDialog: (state, action: PayloadAction<boolean>) => {
      state.showMobileAppReviewDialog = action.payload;
    },
    resetAutomationsState: () => initialState,
  },
});

export const {
  setIsRuleDeleteDialogOpen,
  getFilterCategoriesRequest,
  getFilterCategoriesFail,
  getFilterCategoriesSuccess,
  resetAutomationsState,
  setActiveClosetAutomationPlatform,
  setIsConnectionErrorDialogOpen,
  setIsOnBoardingActive,
  setActiveOnboardingType,
  setIsShareHourDialogOpen,
  setSelectedShareHourData,
  setHourlyRelistLimit,
  setIsRelistHourDialogOpen,
  setSelectedRelistHourData,
  setAutomationError,
  setRelistHourDialogError,
  setRelistLimitError,
  setShowMobileAppReviewDialog,
  setIsRedirectingToRelevantPage,
  //--
  setAutomationsSettings,
  setIsDisableConfirmationDialogOpen,
  setIsAutomationOptionDrawerOpen,
  setSelectedAutomationOption,
  setInitialLoading,
  //--
  getAutomationListRequest,
  getAutomationListSuccess,
  getAutomationListFail,
  //--
  updateAutomationSettingsRequest,
  updateAutomationSettingsSuccess,
  updateAutomationSettingsFail,
  //--
  getActiveClosetPlatformShippingOffersRequest,
  getActiveClosetPlatformShippingOffersSuccess,
  getActiveClosetPlatformShippingOffersFail,
  //--
  createAutomationWorkerRequest,
  createAutomationWorkerSuccess,
  createAutomationWorkerFail,
  //--
  getUserTagsRequest,
  getUserTagsSuccess,
  getUserTagsFail,
  //--
  setUserTagsRequest,
  setUserTagsSuccess,
  setUserTagsFail,
} = automationsSlice.actions;

export default automationsSlice.reducer;
